
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;


@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dspeedup-primary: mat.define-palette(mat.$indigo-palette);
$dspeedup-accent: mat.define-palette(mat.$deep-orange-palette, 500);

// The warn palette is optional (defaults to red).
$dspeedup-warn:  mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$dspeedup-theme: mat.define-light-theme((
  color: (
    primary: $dspeedup-primary,
    accent: $dspeedup-accent,
    warn: $dspeedup-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($dspeedup-theme);

//@import '~@fortawesome/fontawesome-free/css/all.css';

/* You can add global styles to this file, and also import other style files */

html, body { 
  scroll-behavior: smooth;
  height: 100%; }
body { margin: 0; 
    font-family: var(--website-font-family);}
.primary{
  background: var(--primary-color);

}
.primary-icon{
  color: var(--primary-color);

}
@import '~swiper/swiper-bundle.min.css';
@import "src/app/theme/styles/base";
@import "src/app/theme/styles/admin"; 
@import "src/app/theme/styles/gradients";
@import "src/app/theme/styles/rtl";
@import "src/app/theme/styles/common-variables.scss";
@import "src/app/theme/styles/core/core.scss";
@import "src/app/theme/styles/input";
@import "/src/app/theme/styles/clasoft";
@import "material-icons/iconfont/material-icons.css";
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
.orange-spinner circle{
  stroke:orange !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle{
  color:var(--primary-color);
  background-color: var(--primary-color);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color:var(--primary-color);
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: var(--primary-color);
}

/* Safari fix */
// div[fxLayout="column"] > div[fxFlex] {
//   flex: 1 0 auto !important;
// }

div[fxLayout="column"] > div[fxFlex] {
  flex-shrink: 0
}
div[fxLayout="row"] > div[fxFlex] {
  flex-shrink: 0
}
//owl

$font-awesome: FontAwesome;
$round-border: #dddddd;
$font-grey: #aaaaaa;
.product-slick,
.rtl-product-slick,
.product-right-slick,
.rtl-product-right-slick {
    .slick-list {
        overflow: visible;
    }
    .owl-prev,
    .owl-next {
        opacity: 0;
        transform: scale(1);
        transition: all 0.5s ease;
        margin-top: -10px;
        &:before {
            font: normal normal normal 14px/1 $font-awesome;
            opacity: 1;
            color: black;
            background-color: white;
            border-radius: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .owl-next {
        right: 1px;
        &:before {
            content: "\f105";
        }
    }
    .owl-prev {
        left: 1px;
        z-index: 1;
        &:before {
            content: "\f104";
        }
    }
    .owl-item {
        >div {
            //display: flex;
            margin-bottom: 15px;
        }
    }
    &:hover {
        .owl-prev,
        .owl-next {
            opacity: 1;
            transform: scale(1.05);
            transition: all 0.5s ease;
            &:before {
                opacity: 1;
            }
        }
        .owl-next {
            right: 20px;
        }
        .owl-prev {
            left: 20px;
        }
    }
}

.owl-item {
    >div.owl-thumb {
        margin: 5px 5px 0px 0px;
        border: 1px solid $round-border;
        transition: all 0.5s ease;
        cursor: pointer;
    }
    >div.owl-thumb.active {
        border-color: $font-grey;
        transition: all 0.5s ease;
    }
}

.slider-nav,
.rtl-slider-nav,
.slider-right-nav,
.rtl-slider-right-nav {
    .owl-item {
        >div {
            //display: flex;
            margin: 0;
            border: 1px solid $round-border;
            transition: all 0.5s ease;
        }
        &.slick-current {
            >div {
                border-color: $font-grey;
                transition: all 0.5s ease;
            }
        }
        &.slick-active {
            transition: all 0.5s ease;
        }
    }
}

.slider-right-nav,
.rtl-slider-right-nav {
    .owl-item {
        &:first-child {
            >div {
                margin-top: 0;
            }
        }
    }
}

.pro_sticky_info {
    border: 1px solid $round-border;
    padding: 20px;
}

.is_stuck {
    margin-top: 120px;
}

.image-scroll {
    div {
        >div {
            padding-bottom: 20px;
            &:last-child {
                padding: 0;
            }
        }
    }
}
body {
    font-family: var(--website-font-family);
    position: relative;
    background: #ffffff;
    font-size: 14px;
    overflow-x: hidden;
    min-width: unset !important;
}
body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
}
ul {
    padding-left: 0;
    margin-bottom: 0;
}
.row {
    // --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}
.col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}

.main-menu .menu-left .navbar {
    display: inline-block;
    padding: 40px 45px 40px 0;
}
.main-menu .menu-left .navbar mat-icon {
    font-size: 30px;
    color: #222222;
    cursor: pointer;
}
@media (min-width: 576px){
    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}
.main-menu {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
}
.main-menu .menu-left {
    display: flex;
    align-items: center;
}
.main-menu .brand-logo {
    display: inline-block;
    padding-top: 35px;
    padding-bottom: 35px;
}
a {
    transition: 0.5s ease;
    text-decoration: none;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
img, svg {
    vertical-align: middle;
}
html, body { height: 100%; }
body { margin: 0; 
    font-family: var(--website-font-family);}

.dialog-open-scroll {
    overflow: hidden;
  }
